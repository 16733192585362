<template>
  <div class="contact-us second-page">
    联系我们
  </div>
</template>

<script>
  export default {
    name: 'ContactUs'
  }
</script>

<style scoped>
  /*@import "../../assets/style/lib-base.less";*/

</style>
